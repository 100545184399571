<template>
  <v-container fill-height fluid justify-center>
    <div class="sheet-size">
      <v-img
        src="../../../../../assets/images/service-give.png"
        class="img-size d-flex justify-center ma-auto"
      >
      <v-btn
        outlined
        elevation="0"
        color="primary-red"
        class="btn-size text-body-2"
        @click="() => $router.push('/entering')"
      >입점문의</v-btn>
      <v-btn
        outlined
        elevation="0"
        color="primary-blue"
        class="btn-size text-body-2 ml-2"
        @click="() => $router.push('/franchise')"
      >프랜차이즈문의</v-btn>
      </v-img>
        
    </div>
  </v-container>
</template>

<script>
export default {
  // name: 'MainPartnerSection',
  // methods: {
  //   navigateTo(url) { 
  //     this.$router.push(url)
  //   }
  // }
}
</script>

<style lang="scss" scoped>
@import "https://fonts.googleapis.com/css2?family=Jua&display=swap";

.jua {
  font-family: 'Jua', sans-serif;
}
.contents-box {
  max-width: 1000px !important;
}
.round_box {
  border-radius: 8px;
  width: 160px !important;
  height: 40px !important;
}
.img-size {
  height: 100%;
  width: 100%;
  border-radius: 25px !important;
}
.sheet-size {
  width: 1200px;
  height: 600px;
  border-radius: 25px !important;
  margin-top: -40px;
}
.btn-size {
  border-radius: 8px;
  width: 160px !important;
  height: 40px !important;
  top: 76%;
  left: 8%;
}
</style>